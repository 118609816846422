.c-landing {

  .landing-nav {
    position: relative;
    width: 100%;

    .locale-container {
      position: fixed;
      z-index: 1001;
      width: 160px;
      height: 57px;
      right: 0;
      top: 0;
      pointer-events: none;


      @media (min-width: 850px) {
        right: 150px;
      }

      @media (min-width: 1171px) {
        right: 235px;
        position: absolute;
        top: -58px;
      }

      @media (min-width: 1691px) {
        right: 445px;
      }

      .locale-select-container {
        width: 80px;
        height: 100%;
        background-color: $white;
        pointer-events: all;
      }

      .Select__control {
        width: 100%;
        height: 100%;
        border: 0;
        background-color: transparent;
      }
    }
  }

  .landing-content {
    min-height: 0;
    align-items: flex-end;
    padding: 100px 0 50px;

    @media screen and (min-width: 1024px) {
      min-height: calc(100vh - 60px);
      padding: 0;
    }

    .main {
      h1 {
        font-size: $font-size-xx-large;
        color: $theme-accent;
        line-height: 1.02;
        font-family: $base-font-family;
      }

      h2 {
        margin-top: 10px;
        font-size: $font-size-medium;
        line-height: 1.65;
        letter-spacing: -0.2px;
      }

      .build-buttons {
        margin: 16px 0;

        a {
          background-repeat: no-repeat;
          background-size: contain;
          height: 50px;
          width: 150px;
          display: block;

          @media screen and (min-width: 500px) {
            display: inline-block;
          }
        }
        .button-ios-image {
          background-image: url("../../assets/images/app-store@2x.png");
        }

        .button-android-image {
          background-image: url("../../assets/images/google-play@2x.png");
          margin-left: 0;
          margin-top: 12px;

          @media screen and (min-width: 500px) {
            display: inline-block;
            margin-left: 12px;
            margin-top: 0;
          }
        }
      }

      .description {
        color: $disabled-font-color;
        font-size: $font-size-regular;
        line-height: 1.57;
        margin-top: 16px;
      }

      .login-button {
        display: inline-flex;
        margin-top: 20px;
        margin-right: 6px;
      }

      .landing-links {
        margin-top: 20px;
        a {
          margin: 0 5px;

          &:first-child {
            margin-left: 0;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .info-column {
    height: auto;
    display: flex;

    @media screen and (min-width: 1024px) {
      height: calc(100vh - 59px);
    }

    .main {
      padding: 0 50px;

      @media screen and (min-width: 1024px) {
        padding: inherit;
      }
    }
  }

  .image-column {
    min-width: 340px;
    position: relative;
    display: none;

    @media screen and (min-width: 1024px) {
      display: block;
    }

    .phone-screen {
      min-width: 340px;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;

      .phone-image {
        min-width: 340px;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-image: url(../../assets/images/phone@2x.png);
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 500px;
        min-height: 500px;
        min-width: 335px;

        @media screen and (min-width: 1500px) {
          height: 600px;
          min-height: 600px;
          min-width: 335px;
        }
      }
    }
  }
}
