.c-area-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .area-image {
    height: 155px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 2px;
    background-color: $medium-gray;
    margin-bottom: 25px;
  }

  .area-detail {
    padding: 0;
  }

  .area-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #d8d8d8;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .text {
      padding-right: 15px;
      word-break: break-all;
    }
  }

  .area-actions {
    display: flex;

    > .c-button {
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
