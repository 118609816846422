.c-teams-details {
  min-height: unset;
  padding-top: 38px;
  padding-bottom: 28px;

  &__edit-btn {
    margin-left: auto;
    margin-right: 8px;
  }
}
