.c-select {
    height: 100%;
    font-family: $base-font-family;

    .Select__control {
        min-height: 54px;
        background-color: $white;
        border: solid 1px #e6e6e6;
        border-radius: 4px;
        color: $theme-text;
        cursor: pointer;
        display: flex;

        &--is-disabled {
          background-color: #f9f9f9;
        }

        &.Select__control--menu-is-open {
              background-color: $white;
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 0;

              .Select__single-value {
                  color: $theme-text !important;
              }

            .c-icon {
                transform: rotate(180deg);
            }
        }

        .Select__value-container {
            padding-left: 15px;
            padding-right: 15px;
            height: 100%;
        }

        .Select__multi-value {
          display: flex;
          padding: 0 !important;
          background-color: $theme-accent-light;
          height: 100%;
          border: 1px solid $theme-accent;

          .Select__multi-value__label {
            border-right: 0;
            font-size: 1em;
            color: $base-font-color;
            display: flex;
            align-items: center;

          }
          .Select__multi-value__remove {
            padding-top: 1px;
          }
        }
        .Select__value-container--is-multi {
          display: flex;
          align-content: center;
          padding: 5px 3px;
        }

        .Select-input {
          display: none;
        }

        .Select__single-value {
            height: 100%;
            display: flex;
            align-items: center;
            font-size: $font-size-regular;
            color: $gray;
            letter-spacing: 0.1px;
        }

        .Select__indicator {
            padding-right: 15px;
            width: 25px;
            padding-left: 0;
            .c-icon {
              margin-left: 0;
            }
        }

        .Select__indicator-separator {
          display: none;
        }
    }

    .Select__placeholder {
        line-height: 40px;
        margin-right: 25px;
    }

    .Select__menu {
        border-radius: 0;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;
        background-color: #fff;
        border: solid 1px #e6e6e6;
        border-top-color: solid 1px rgba(#979797, 0.5);
        box-shadow: 0 0 0;

        .Select__option {
            font-size: $font-size-regular;
            color: $theme-text !important;
            padding-top: 12px;
            padding-bottom: 12px;

            &.Select__option--is-focused {
                background-color: rgba($theme-accent, 0.3);
                border-radius: 0;
            }

            &.Select__option--is-selected {
                background-color: rgba($theme-accent, 0.2);
                border-radius: 0;
            }
        }
    }

    &.-dark {

        .Select__control {
            color: rgba($white, 0.5) !important;
            background-color: transparent;
            border-radius: 0;
            border: 0;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: $font-size-regular;
            color: $gray;
            letter-spacing: 0.1px;

            .Select__single-value {
                color: rgba($white, 0.5) !important;
                font-size: $font-size-small;
                font-weight: $font-weight-bold;
            }
        }

        .c-icon {
            fill: rgba($white, 0.5);
        }

        .Select__menu {
            margin-top: 0;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            padding-top: 8px;
            padding-bottom: 8px;
            border: 0;

            .Select-option {
                font-weight: $font-weight-bold;
            }
        }
    }

    &.-map {
        .Select__control {
            color: $white !important;
            box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.2);
            background-color: rgba(#333333, 0.5);
            border-radius: 2px;
            border: 0;
            height: 37px;
            min-height: 37px;

            .Select__single-value {
                color: $white !important;
                font-size: $font-size-small;
                font-weight: $font-weight-bold;
            }

            .Select__placeholder {
                color: $white !important;
                line-height: 37px;
            }

            .Select__input {
                > input {
                    color: $white !important;
                    padding: 10px 0 10px 5px;
                }
            }
        }

        .c-icon {
            fill: rgba($white, 0.5);
        }

        .Select-menu-outer {
            margin-top: 0;
            box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.2);
            padding-top: 8px;
            padding-bottom: 8px;
            border: 0;

            .Select-option {
                font-weight: $font-weight-bold;
            }
        }

        &.is-open {

            .Select-control {
                color: $white !important;
                box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.2);
                background-color: rgba(#333333, 0.5);
                border-radius: 2px;
                border: 0;

                .Select-value-label {
                    color: $white !important;
                }
            }

            .c-icon {
                transform: rotate(180deg);
                fill: $dark-gray;
            }
        }
    }
}
