.c-location-search  {
  position: absolute;
  top: 20px;
  right: 120px;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  .c-location-search-menu {
    display: flex;
    flex-direction: column;
    width: 260px;
    background: $theme-base;
    border-radius: 2px;
    button {
      &.-map {
        width: 37px;
      }
    }
    input, .Select__control {
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px 0px inset;
      background-color: $theme-secondary !important;
      border-radius: 2px;
      border: 0px;
      height: 40px;
      color: $white;
      width: 100%;
      &::placeholder {
        color: $white
      }
    }
    input {
      padding: 2px 16px;
    }
    .google-places-autocomplete__suggestions-container {
      z-index: 1001;
    }
    .google-places-autocomplete {
      margin-bottom: 6px;
    }
    .Select__menu {
      z-index: 9999 !important;
    }
  }
}
