.c-pagination {
  display: flex;
  gap: $global-spacing-4;
  align-items: center;
  &__input-wrapper {
    display: flex;
    gap: $global-spacing-4;
    align-items: center;
  }
  &__input {
    .c-input__input {
      max-width: 48px;
      padding: $global-spacing-2 0;
      text-align: center;
      border: 2px solid rgba(85, 85, 85, 0.1);
    }
  }
  &__count {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: $neutral-600;
  }
}