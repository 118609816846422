.c-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 40px; // TODO: Get spacings
  border-radius: 24px;
  color: $neutral-300;
  box-sizing: border-box;
  border: 0px;

  font-weight: 500;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: 1;
  min-height: 40px;

  white-space: nowrap;

  cursor: pointer;
  outline: 0;
  text-transform: uppercase;

  &:disabled,
  &--disabled {
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      cursor: not-allowed;
    }
  }

  &--primary {
    background-color: $primary-500;
    box-shadow: 0 0 0 2px $primary-500;
    &:hover,
    &:focus {
      background-color: $primary-600;
    }

    &:active {
      background-color: $primary-500;
      box-shadow: 0 0 0 2px $primary-600;
    }
  }

  &--secondary,
  &--secondary-light-text {
    background-color: transparent;
    box-shadow: 0 0 0 1px $primary-500;
    color: $neutral-700;
    &:hover,
    &:focus {
      box-shadow: 0 0 0 2px $primary-500;
    }

    &:active {
      box-shadow: 0 0 0 2px $primary-600;
    }
  }

  &--secondary-light-text {
    color: $neutral-300;
  }

  &--default {
    background-color: $neutral-600;
  }

  &--is-icon {
    padding: $global-spacing-2;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    min-width: 40px;
  }

  &__inline-icon {
    margin-right: $global-spacing-1;
  }

  // LEGACY FROM HERE
  &.-hero {
    border-color: $theme-accent;
    background: $theme-secondary;
    color: $white;
    text-transform: uppercase;
    margin-top: 14px;

    &:hover {
      background-color: lighten($theme-secondary, 7%);
    }
    &:active {
      background-color: lighten($theme-secondary, 14%);
    }
  }

  &.-light {
    border-color: $theme-accent;
    background: $light-green;
    color: $gray;

    &:active {
      background-color: darken($light-green, 7%);
    }
  }

  &.-small {
    padding: 0 20px;
    font-size: 12px;
  }

  &.-right {
    margin-left: auto;
  }

  &.-white {
    background-color: $white;
    border: 0;

    &:active {
      background-color: darken($white, 7%);
    }
  }

  &.-transparent {
    border-color: rgba($theme-secondary, 0.3);
    background: $theme-base;
    color: $gray;

    &:active {
      background-color: darken($light-green, 7%);
    }
  }

  &.-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    padding: 0;
    flex-shrink: 0;
  }
}
