.c-form-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f7f7f7;
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);

    .container {
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}