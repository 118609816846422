.c-tab-group {
  display: inline-flex;
  border: 1px solid #6D6D6D;
  border-radius: 24px;

  .c-chip {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    border-radius: 20px;
    padding: 13px 20px;
    text-transform: uppercase;
  }

  .c-chip--is-selected {
    font-weight: 500;
  }
}
