.c-banner {
  display: flex;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;

  &--info {
    border-color: $theme-accent;
    background-color: $light-green;
  }
}
