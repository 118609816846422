.l-layers-manager {
  padding-top: 16px;
  padding-bottom: 80px;
  
  .section {
    padding-top: 30px;
  }

  .title {
    font-weight: bold;
    padding-bottom: 10px;
  }
}
