.c-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: 999999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  .modal-frame {
    position: relative;
    background-color: $white;
    padding: 30px;

    .modal-close-icon {
      position: absolute;
      top: 12px;
      right: 12px;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .modal-content {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
