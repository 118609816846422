.c-modal-content {
  min-width: 300px;

  .modal-content-header {
    font-weight: 700;
    border-bottom: 2px solid $theme-accent;
  }

  .modal-content-steps-index {
    display: flex;

    .modal-content-steps-index-item {
      position: relative;
      opacity: 0.5;
      margin: 0 32px 0 0;
      padding: 0 0 5px 0;

      &.-active {
        opacity: 1;

        &::after {
          content: '';
        }
      }

      &:hover {
        &::after {
          content: '';
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: $theme-accent;
        transform: translateY(100%);
      }
    }
  }

  .modal-content-steps-content {
    display: block;

    .modal-content-steps-content-item {
      position: relative;
      padding: 0 0 0 20px;
      min-height: 50px;

      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        width: 8px;
        height: 8px;
        background: $theme-accent;
        border-radius: 50%;
      }
    }

    > ul {
      li {
        display: flex;
        align-content: center;
        justify-content: center;
      }
    }
  }

  .modal-content-action {
    display: flex;
    justify-content: center;
    align-items: center;

    .c-button {
      flex: 1 1 50%;
      max-width: 200px;
      display: block;
    }
  }
}
