$login-offset: 60px;

.c-login {
  padding-top: $login-offset;
  width: 100%;
  height: calc(100vh - #{$nav-height});

  .login-content {
    border: solid $medium-gray 1px;
    border-bottom: 0;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  .login-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 55px;
    margin-top: 90px;

    > svg {
      margin-bottom: 10px;
    }

    .text-message {
      margin-top: 22px;

      span {
        font-size: 16px;
      }
    }
  }

  .login-button {
    background: black;
    color: white;
    border-radius: 50px;
    margin-bottom: 20px;

    a {
      color: white;
      font-weight: $font-weight-bold;
      padding: 15px;
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: capitalize;
    }

    .network-container {
      display: flex;
      align-items: center;

      svg {
        margin-right: 15px;
      }
    }

    &.-facebook {
      background-color: #3b5998;
    }

    &.-twitter {
      background-color: #00aced;
    }

    &.-google {
      background-color: #d62d20;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
