@function rem($px, $base: 16px) {
  @return ($px / $base) * 1rem;
}

.c-social {
  display: none;
  padding: rem(45px) 0;
  border-top: solid 1px #d6d6d9;

  .columns:last-child {
    text-align: right;
  }

  .c-social__buttons {
    margin-top: rem(17px);
    div, iframe {
      margin-left: 5px;
      margin-right: 5px;
    }

    .fb_iframe_widget {
      top: -2px;
    }
  }

  .c-social__icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    float: right;
    width: 100%;
    max-width: rem(135px);
    margin-top: rem(15px);

    .icon {
      fill: $theme-secondary;
      height: rem(22px);
      width: rem(22px);
    }
  }

  @media screen and (min-width: 240px) {
    display: block;
    background-color: $white;
  }

  .text {
    &.-color-5 {
      color: $disabled-font-color;
    }

    &.-title-xxs {
      font-size: 14px;
      letter-spacing: 0.1px;
    }
  }
}
