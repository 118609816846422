.u-p-relative {
  position: relative;
}

.u-p-absolute {
  position: absolute;
}

.u-p-inherit {
  position: inherit;
}
