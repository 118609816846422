.c-leaflet-attribution {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 11px;
  padding: 2px;
  height: 16px;
  color: #333;

  a {
    color: #0078A8;
  }

  .custom-attribution {
    display: inline-block;
  }
}
