$global-spacing-1: 4px;
$global-spacing-2: 8px;
$global-spacing-3: 12px;
$global-spacing-4: 16px;
$global-spacing-5: 20px;
$global-spacing-6: 24px;
$global-spacing-7: 28px;
$global-spacing-8: 32px;
$global-spacing-9: 36px;
$global-spacing-10: 40px;
$global-spacing-12: 48px;
$global-spacing-15: 60px;
$global-spacing-20: 80px;
$global-spacing-25: 100px;