.c-member-list {
  padding-left: 10px;
  padding-bottom: 10px;

  .horizontal-field-left-aligned {
    display: flex;
    padding-top: 20px;
    align-items: center;
    justify-content: space-between;
  }

  .user-label {
    margin-right: 15px;
  }
}
