$widths: 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;

@each $width in $widths {
  .u-w-#{$width} {
    width: $width * 1%;
  }

  .u-w-min-#{$width} {
    min-width: $width * 1%;
  }
}

.u-w-min-unset {
  min-width: unset;
}
.u-w-unset {
  width: unset;
}