.c-custom-layers {
  position: relative;
  background-color: $white;
  padding: 15px;
  border: 1px solid $border-light-gray;
  border-radius: 4px;
  margin-bottom: 20px;
  overflow: auto;
  height: 420px;

  .form-control {
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .info-button {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
  }
}
