// layout
$nav-height: 56px;
$hero-height: 100px;
$footer-height: 60px;
$max-width: 1080px;

// colors
$white: #ffffff;
$gray: #555555;
$pale-gray: #8a8a8a;
$light-gray: #f2f2f2;
$medium-gray: #d1cfcf;
$border-light-gray: #e6e6e6;
$border-gray: #404040;
$dark-gray: #333333;
$parrot-green: #97be32;
$light-parrot-green: #cbde98;
$light-green: #f4f8ea;

// app palette (prefer using palette over colors when possible)
$theme-base: $light-gray;
$theme-secondary: $dark-gray;
$theme-accent: $parrot-green;
$theme-accent-light: $light-parrot-green;
$theme-text: $gray;

// fonts
$font-size-small: 13px;
$font-size-regular: 14px;
$font-size-medium: 17px;
$font-size-large: 35px;
$font-size-x-large: 47px;
$font-size-xx-large: 59px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

// base settings
$base-font-family: "Fira Sans", sans-serif;
$base-font-size: $font-size-regular;
$base-font-color: $gray;
$disabled-font-color: #999;

// eases
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);

// size settings
$global-spacing-unit: 24px;
$global-spacing-unit-very-tiny: 4px;
$global-spacing-unit-tiny: 6px;
$global-spacing-unit-small: 12px;
$global-spacing-unit-medium: 16px;
$global-spacing-unit-large: 48px;
$global-spacing-unit-huge: 96px;
