// defaults
a {
  cursor: pointer;
}

h3, h4 {
  padding-bottom: 20px;
}

h3 {
  font-weight: $font-weight-bold;
}

.text {
  color: $gray;

  &.-logo-title {
    color: $theme-accent;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }

  &.-large-title {
    font-size: $font-size-x-large;
    font-weight: $font-weight-light;
  }

  &.-medium-title {
    font-size: $font-size-large;
    font-weight: $font-weight-light;
  }

  &.-small-title {
    font-size: $font-size-medium;
    font-weight: $font-weight-light;
    text-transform: capitalize;
    line-height: 1.5;
  }

  &.-x-small-title {
    text-transform: uppercase;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    line-height: 1.62;
  }

  &.-question-number {
    font-size: $font-size-regular;
    font-weight: $font-weight-bold;
  }

  // color overrides
  &.-white {
    color: $white;

    a {
      color: $white;
    }
  }

  &.-green {
    color: $theme-accent;

    a {
      color: $theme-accent;
    }
  }
}
