.c-question-card {

    .question-card {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15), inset 3px 0 0 0 $theme-accent;
        border-radius: 3px;
        background: $white;
        margin-bottom: 20px;

        &.-title {
            margin-top: -25px;
            padding: 40px;
            padding-top: 60px;
            border: solid 1px $border-light-gray;
            box-shadow: none;
        }

        .questions {
            padding: 25px;

            .-question-number {
                margin-bottom: 5px;
                display: block;
            }

            .type-select {
                margin: 25px 0;
                max-width: 60%;
                text-transform: capitalize;
            }
        }

        .question-actions {
            height: 40px;
            border-top: solid 1px rgba(#979797, 0.2);
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding-right: 25px;

            .rsbc-switch-button {
                margin-left: 15px;
            }

            .required-label {
                margin-left: 15px;
                padding-left: 15px;
                border-left: solid 1px rgba(#979797, 0.2);
            }

            .switch {
                margin-left: 15px;
            }
        }

        .question-enter {
            opacity: 0.01;
        }

        .question-enter.question-enter-active {
            opacity: 1;
            transition: opacity 1000ms ease-in;
        }

        .question-leave {
            opacity: 1;
        }

        .question-leave.question-leave-active {
            opacity: 0.01;
            transition: opacity 1000ms ease-in;
        }

        .question-options {

            .delete-button {
                transform: rotate(45deg);
            }

            .option-input {
                display: inline-block;
                width: 70%;
                margin-bottom: 10px;
            }
        }

        .question-more-info {
            display: block;

            .conditional-settings {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin-top: 30px;
                margin-bottom: 10px;

                .more-info-select {
                    width: 160px;
                    margin-right: 15px;
                }
            }

            .child-input {
                width: 70%;
            }
        }
    }

    .question-footer {
        padding: 10px 50px 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        .only-show-select {
            width: 160px;
            margin-right: 15px;
        }
    }
}


// reaact transition effects
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-out;
}
