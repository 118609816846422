.c-context-menu {
  $this: &;

  position: absolute;
  background-color: $neutral-300;
  min-width: 160px;
  // To override default styles
  border: 0;
  transform: translateY(-2px);

  .szh-menu__item:not(:first-child) {
    margin-top: -1px;
  }

  &__item {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $neutral-600;
    padding: 10px 16px 8px;
    border: 1px solid $neutral-500;
    cursor: pointer;

    &:hover {
      background-color: $primary-400;
    }
  }

  &--hover {
    display: none;
  }

  &__toggle {
    padding: 8px;

    &:hover, &.szh-menu-button--open {
      #{$this}__icon {
        display: none;
      }

      #{$this}--hover {
        display: block;
      }
    }
  }
}
