.c-layers-show {
  .list-row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-top: 16px;
    
    .layer-name {
      text-transform: uppercase;
      display: flex;
      align-items: center;

      label {
        margin-left: 10px;
      }
    }
  }
  
  .layers-title {
    border-bottom: 1px solid $medium-gray;
    padding-bottom: 16px;
    margin-top: 30px;
    color: $theme-accent;
  }

  .list-header {
    border: 0;
  }

  .c-icon {
    margin-right: 18px;
  }
}
