.u-visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;border: 0;
}

.u-flex {
  display: flex;
}

.u-flex-1 {
  flex: 1;
}

.u-translate-y-half {
  transform: translateY(-50%);
}

.xl\:u-translate-y-half {
  @media (min-width: 1280px) {
    transform: translateY(-50%);
  }
}