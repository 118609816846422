.c-form {
  position: relative;
  font-family: $base-font-family;

  &.-templates {
    background: $white;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  label:not(.c-button) {
    padding-right: 15px;
  }

  textarea {
	  border: solid 1px #e6e6e6;
    width: 100%;
    min-height: 200px;

    .-small {
      min-height: 160px;
    }
  }

  &.-nav {
    .form-error {
      position: absolute;
      margin-top: 0;
      padding-left: 5px;
      right: 0;
      top: 50%;
      transform: translate(100%, -50%);
    }
  }

  .form-error {
    display: block;
    margin-top: 6px;
    color: red;
  }

  input {
    height: 40px;
	  border-radius: 4px;
	  background-color: #ffffff;
	  border: solid 1px #e6e6e6;
    padding: 0 15px;
    font-family: $base-font-family;
    font-weight: $font-weight-regular;

    &::placeholder {
      color: rgba($theme-text, 0.3);
    }

    &.-title {
      border: 0;
      border-bottom: solid 1px $border-light-gray;
      border-radius: 0;
      width: 100%;
      padding: 0 0 25px 0;
      font-size: 45px;
      letter-spacing: 0.3px;
      line-height: 45px;
      height: 80px;
      font-weight: $font-weight-light;
    }

    &.-question {
      border: 0;
      border-bottom: solid 1px rgba(#858181, 0.3);
      border-radius: 0;
      padding: 0;
      width: 100%;
    }

    &[type='checkbox'] {
      height: 0px !important;
    }

    &[type='file'] {
      height: auto;
      background-color: transparent;
      border: none;
    }

    &.file-hidden {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }

  .horizontal-field {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .horizontal-field-left-aligned {
    display: flex;
    flex-direction: row;
    padding-top: 10px;

    input, .delete-button, .user-label, .c-checkbox {
      flex: 1;
    }

    .hidden{
      visibility: hidden;
    }
  }

  .input-group {
    padding-top: 30px;
    #add-member {
      margin-right: 10px;
    }
    .form-control, .Select{
      padding-top: 10px;
      input {
        width: 100%;
      }
    }
  }

  .-main {
    min-height: 500px;
    height:auto !important;
    height: 500px;
  }

  &.-nav {
    height: $footer-height;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .areas-inputs {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }

    .upload-field {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .text {
        margin-right: 15px;
      }
    }

    .separator {
      width: 1px;
      margin: 0 30px;

      &.-vertical {
        border-left: solid 1px rgba($pale-gray, 0.2);
        height: 100%;
      }
    }
  }

  .is-searchable {

    input {
      border: 0;
      height: 100%;
      padding: 0;
    }
  }
}
