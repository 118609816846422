.c-map-control-panel {
  position: absolute;
  background-color: $white;
  bottom: $global-spacing-10;
  max-width: 400px;
  width: 100%;
  font-family: $base-font-family;

  &__team-areas {
    position: relative;
    margin-top: $global-spacing-6;
    min-height: 100px; // For the loading spinner space
  }

  &__team-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 0;
    color: $neutral-700;
    margin-bottom: $global-spacing-3;
  }

  &__sub-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $neutral-700;
    padding: 0;
    margin-top: $global-spacing-1;
    margin-bottom: $global-spacing-3;
  }

  &__area-card-content {
    margin-top: 3px;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: -12px;
    margin-left: -6px;
    margin-right: -6px;
    margin-bottom: $global-spacing-3;
  }

  &__grid-item {
    width: 50%;
    flex: 0 0 50%;
    padding: 12px 6px 0;
    &--slim {
      // padding: 12px;
      .c-card {
        display: flex;
        gap: 12px;
        align-items: center;
      }
      .c-card__image {
        width: 52px;
        height: 52px;
      }
      .c-card__title {
        margin-top: 0px;
      }
    }
  }

  &__area-card {
    width: 100%;
    height: 100%;
  }

  &__area-card-image {
    height: 90px;
  }

  @include before(md) {
    max-width: 200px;
    &__grid-item {
      width: 100%;
      flex: 0 0 100%;
    }
  }
}
