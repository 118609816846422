.c-data-table {
  $this: &;

  &__header {
    background-color: #f7f7f7;
  }

  &__row {
    border-bottom: 2px solid $neutral-400;
  }

  &__checkbox {
    min-width: 24px;
  }

  th, td {
    text-align: left;
    vertical-align: middle;
  }

  th, th button {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    padding: 23px 24px;
    text-transform: uppercase;
    color: $neutral-700;
    font-family: inherit;
    white-space: nowrap;
  }

  th button {
    padding: 0px;
  }

  td {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 12px 24px 14px;
    color: $neutral-7000;
  }

  td#{$this}__action-cell {
    padding: 0;
    text-align: right;
  }

  &__action-toggle {
    padding: 8px 34px 8px 8px;
    margin-left: auto;
    position: relative;
    top: -1px;
  }

  &__pagination {
    margin-top: $global-spacing-10;
    display: flex;
    justify-content: flex-end;
    position: relative;
    right:  2px;
    bottom: 2px; // Fix Chrome issue cutting off radius on overflow hidden parents
  }

  &__sort-button {
    display: flex;
    align-items: center;
    gap: $global-spacing-2;
  }

  &__checkbox-cell {
    left: 0;
    position: sticky;
    z-index: 999;
    background: white;
  }

  &__checkbox-header-cell {
    background: #f7f7f7;
  }
}
