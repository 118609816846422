* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  color: $base-font-color;
}

#app, [data-reactroot]{
  height:100%
}

li {
  list-style: none;
}

img {
  max-width: 100%;
}

a, li {
  /* Avoid important here because we do want underline styles for some links */
  text-decoration: none;
}

.-no-scroll {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
}

.sr-only {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

button {
  cursor: pointer;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
