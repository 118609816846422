$spinner-size: 30px;
$spinner-width: 3px;
$spinner-color: $theme-accent;
$spinner-bg-color: $theme-base;
$spinner-velocity: 0.6s;

.c-loading-spinner {
  position: relative;
  min-width: $spinner-size;
  min-height: $spinner-size;

  &.-full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.-bg {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba($spinner-bg-color, 0.2);
    }

    &.-blank {
      &::before {
        border: 1px solid $theme-base;
        border-radius: 4px;
        background-color: $theme-base;
      }
      &::after {
        z-index: 2;
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: $spinner-size;
    height: $spinner-size;
    transform: translate(-50%, -50%) rotate(0deg);
    animation: rotate $spinner-velocity linear infinite;
    border: $spinner-width solid transparent;
    border-top-color: $theme-accent;
    border-right-color: $theme-accent;
    border-radius: 50%;
  }

  &.-big::after {
    border-width: $spinner-width * 2;
  }

  &.-btn {
    color: transparent;
    pointer-events: none;
    outline: 0;
    &::after {
      border-top-color: $theme-secondary;
      border-right-color: $theme-secondary;
    }
  }
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
