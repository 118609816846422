.c-table {
    position: relative;
    border: 0;
    color: $theme-text;
    overflow-x: auto;

    .rt-table {
        min-width: 68.7rem;
    }

    .rt-thead {
        background: transparent;
        border: 0;
        padding: 0;
        text-align: left;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        background: #F7F7F7;
        padding: 20px 25px;
        text-align: left;
        text-transform: uppercase;
        font-weight: $font-weight-bold;

        .-cursor-pointer {
            cursor: pointer;
        }

        .rt-th {
            text-align: left;
        }
    }

    .rt-tr {
        flex: 1 0 auto;
        display: inline-flex
    }

    .rt-tr-group {
        padding: 25px 25px;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border-bottom: dashed 1px #bbb;

        .rt-td {
            padding-right: 25px;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            transition-property: width, min-width, padding, opacity;
            transition: .3s ease;

            .link {
                color: $theme-accent;
            }

            .table-link {
                display: flex;
                flex-direction: column;

                &.-row {
                  flex-direction: row;
                }

                .link {
                    margin-bottom: 5px;
                    color: $gray;
                }
            }

            &.report-link {

                .link {
                    float: right;
                }
            }

            &.status {
                text-transform: capitalize;
            }
        }
    }

    .-pagination {
        z-index: 1;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 30px;

        .-btn {
            background-color: $theme-accent;
            border: solid 1px #bddd94;
            appearance: none;
            display: block;
            width: 40px;
            height: 100%;
            border: 0;
            border-radius: 3px;
            padding: 6px;
            font-size: 1em;
            color: rgba(0, 0, 0, 0.6);
            // background: rgba(0, 0, 0, 0.1);
            transition: all .1s ease;
            cursor: pointer;
            outline: none;
            position: relative;
        }

        .-btn[disabled] {
            opacity: .5;
            cursor: default
        }

        .-previous {

            .-btn {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                &:before {
                    width: 0;
                    height: 0;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-right: 10px solid #fff;
                    display: block;
                    position: absolute;
                    top: 13px;
                    left: 15px;
                    content: '';
                }
            }
        }

        .-center {
            background-color: $white;
            border: solid 1px $theme-accent;
        }

        .-next {

            .-btn {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                &:before {
                    width: 0;
                    height: 0;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-left: 10px solid #fff;
                    display: block;
                    position: absolute;
                    top: 13px;
                    left: 15px;
                    content: '';
                }
            }
        }

        .-pageInfo {
            display: inline-block;
            margin: 3px 20px;
            white-space: nowrap
        }

        .-pageJump {
            display: inline-block;

            input {
                width: 35px;
                text-align: center;
                padding-right: 0;
            }
        }
    }

    .rt-noData {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background: rgba(255, 255, 255, 0.8);
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        z-index: 1;
        pointer-events: none;
        padding: 20px;
        color: rgba(0, 0, 0, 0.5)
    }

    .-loading {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.8);
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        z-index: 2;
        opacity: 0;
        pointer-events: none;
    }

    input, select {
        border: 0;
        background: #fff;
        padding: 5px 7px;
        font-size: inherit;
        border-radius: 3px;
        font-weight: normal;
        outline: none;
        text-align: center;
    }
}
