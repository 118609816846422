.c-icon {
  // margin: 0 5px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;

  &.-x-small {
    width: 16px;
    height: 10px;
    margin-left: 15px;
  }

  &.-small {
    width: 15px;
    height: 15px;
  }

  &.-medium {
    width: 24px;
    height: 24px;
  }

  &.-big {
    width: 32px;
    height: 32px;
  }

  &.-huge {
    width: 74px;
    height: 74px;
  }

  &.-green {
    fill: $theme-accent;
  }

  &.-gray {
    fill: $dark-gray;
  }

  &.-theme-gray {
    fill: $theme-text;
  }

  &.-white {
    fill: $white;
  }

  &.-transparent {
    fill: transparent;
  }

  //transformations
  &.-rotate-right {
    transform: rotate(-90deg);
  }

  &.-no-margin {
    margin-left: 2px;
  }
}
