.l-team-invitations {
  padding: 20px 0;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    line-height: 36px;
    color: #555555;
  }
}
