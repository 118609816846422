.c-switcher {
  height: 20px !important;
  border-radius: 10px !important;
  width: 40px !important;
  border: 1px solid $border-light-gray !important;
  background: $light-gray !important;

  &.on {
    background: $theme-accent !important;
  }

  &.disabled {
    opacity: 0.5;
  }

  .switch-toggle {
    width: 18px;
    height: 18px;
    border-radius: 10px;
  }
}
