.c-map {
  min-height: 500px;
  height: 100%;
  position: relative;
  z-index: 0;

  &--within-hero {
    height: 600px;
  }

  .mapboxgl-map {
    position: absolute !important;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  &__controls {
    z-index: 2;
    position: absolute;
    right: $global-spacing-10;
    top: $global-spacing-10;
    display: flex;
    gap: $global-spacing-4;

    &--edit {
      top: unset;
      bottom: $global-spacing-10;
      flex-direction: column;
      gap: $global-spacing-3;
      z-index: 1;
    }
  }

  &__search-controls {
    position: relative;
  }

  &__search-panel {
    position: absolute;
    top: 0;
    right: 0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    background-color: $neutral-300;
    padding: $global-spacing-5;
    font-family: $base-font-family;
    width: 352px;
    padding-top: $global-spacing-25;
  }

  &__search-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin-top: $global-spacing-6;
    text-transform: uppercase;
    color: $neutral-700;
  }

  &__autocomplete-section {
    width: 100%;
    border-bottom: 2px solid $neutral-400;
    padding: $global-spacing-5;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    gap: $global-spacing-3;
    align-items: center;
  }

  &__geocoder {
    flex-grow: 1;
  }

  // Geocoder styling
  .mapboxgl-ctrl-geocoder {
    width: 100%;
    box-shadow: none;
    font-size: 14px;
    line-height: 14px;
    position: relative;
    .mapboxgl-ctrl-geocoder--icon-search,
    .mapboxgl-ctrl-geocoder--icon-loading {
      display: none !important;
    }

    .mapboxgl-ctrl-geocoder--pin-right {
      position: absolute;
      z-index: 1;
      top: 3px;
      right: 42px;
    }

    .mapboxgl-ctrl-geocoder--button {
      background: transparent;
    }

    .suggestions {
      border: 1px solid $neutral-500;
      box-shadow: none;
    }

    &::after {
      content: "";
      display: block;
      background-image: url("../../assets/images/icons/Search.svg");
      width: 24px;
      height: 24px;
      right: $global-spacing-5;
      top: $global-spacing-2;
      position: absolute;
    }
  }

  &__search-input {
    background: $neutral-400;
    border-radius: 20px;
    color: $neutral-600;
    border: 0px;
    padding: $global-spacing-3 68px $global-spacing-3 $global-spacing-5;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    font-family: $base-font-family;
    width: 100%;
    position: relative;

    &::placeholder {
      color: $neutral-600;
    }
  }

  &__panel-input {
    margin-top: $global-spacing-3;
    width: 100%;
    .c-input__input-wrapper,
    .c-input__input {
      width: 100%;
    }

    .c-input__input {
      font-family: "Fira Sans", sans-serif;
      padding: 10px 16px;
      color: $neutral-700;
    }
  }

  &__zoom-controls {
    background-color: $neutral-300;
    display: flex;
    flex-direction: column;
    border: 2px solid $neutral-400;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }
  &__control {
    padding: 6px $global-spacing-2;
    border-radius: 0px;
    &:not(:nth-child(1)) {
      border-top: 2px solid $neutral-400;
    }
    &:hover,
    &:focus {
      background-color: $neutral-400;
    }

    &--single {
      background-color: $neutral-300;
      border-radius: 6px;
      border: 2px solid $neutral-400;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      gap: 2px;
      text-transform: uppercase;
      font-weight: 500;
      font-family: $base-font-family;
      font-size: 14px;
    }

    &--no-shadow {
      box-shadow: none;
    }

    &:disabled {
      &.c-map__control-label,
      img {
        opacity: 0.5;
      }
      cursor: not-allowed;
    }
  }

  &__control-label {
    flex: 1 0 auto;
    text-align: center;
  }
}

.c-map-comparison {
  // Overrides for mapbox-gl-comparison
  .mapboxgl-compare {
    z-index: 11;
    width: 8px;

    .compare-swiper-vertical {
      width: 48px;
      height: 48px;
      left: -24px;
      margin: -24px 1px 0;
      background-size: contain;
      background-color: $white;
      box-shadow: none;
      background-image: url("../../assets/images/icons/MapSlider.svg");
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      margin: 0px;
    }
  }
}

.mapbox-gl-draw_ctrl-draw-btn {
  display: none !important; // Hide in favour of our own controls (src/components/ui/Map/components/EditControls.tsx).
}
