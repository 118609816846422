.c-article {
  &__top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $global-spacing-10;
    gap: $global-spacing-3;
    flex-wrap: wrap;
  }

  &__actions {
    display: flex;
    gap: $global-spacing-3;
  }

  &--size-small {
    .c-article__top-row {
      margin-bottom: 26px;
    }
  }

  .section {
    padding-top: 30px;
  }

  .admin-selected {
    color: $theme-accent;
    padding-left: 10px;
  }

  .title {
    font-weight: bold;
    padding-bottom: 10px;
  }

  .area-image-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    .area-item {
      padding: 10px;
      width: 33.333%;
      text-align: center;

      .area-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 3px;
        background-color: $medium-gray;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 80px;
        height: 60px;
      }
      figcaption {
        word-wrap: break-word;
      }
    }

    > .area-item:last-child {
      border: none;
    }
  }
}
