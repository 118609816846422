.l-template {
    position: relative;

    .template-meta {
        padding: 30px 0 80px;
        background: $theme-base;

        label {
            margin-bottom: 15px;
            display: block
        }
    }

    .template-fields {
        background: $white;
        padding-bottom: 20px;
    }

    .add-question {
        min-height: 40px;
        background: $white;
        padding-bottom: 120px;

        .add-button {
            padding-top: 25px;
            border-top: solid 1px rgba(#979797, 0.2);

            button {
                float: right;
            }
        }
    }

    .template-status {
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .status-label {
            padding: 0 10px;
        }
    }
}