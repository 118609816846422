$primary-400:     #E3ECC6;
$primary-500:     #94BE43;
$primary-600:     #799D33;

$secondary-500:   #F74848;

$neutral-300:     #FFFFFF;
$neutral-400:     #F2F2F2;
$neutral-500:     #DCDCDC;
$neutral-600:     #555555;
$neutral-700:     #333333;
$neutral-7000:    #1A1A19;
$black:           #000000;
