/* @tailwind base; TODO: Fix issues to base styling when we apply this! */
@tailwind components;
@tailwind utilities;

@layer components {
  .c-map-legend {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }
}

/* IMAGE CAROUSEL STYLES */

.c-carousel {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.c-carousel__viewport {
  overflow: hidden;
  width: 100%;
}

.c-carousel__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.c-carousel__viewport.is-dragging {
  cursor: grabbing;
}

.c-carousel__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
}

.c-carousel__slide {
  padding-left: 10px;
  min-width: 100%;
  position: relative;
}

.c-carousel__slide hr {
  border-width: 0px, 2px, 2px, 2px;
  border-style: solid;
  border-color: #5555551a;
}

.c-carousel__slide__inner {
  position: relative;
  overflow: hidden;
  height: 450px;
}

.c-carousel__slide__footer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 28px 24px;
  font-size: 16px;
}

.c-carousel__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: auto;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  transform: translate(-50%, -50%);
}

.c-carousel--thumb {
  padding-top: 0;
  margin-top: -12px;
}

.c-carousel__container--thumb {
  cursor: default;
  margin-left: -8px;
}

.c-carousel__slide--thumb {
  padding-left: 8px;
  padding-right: 4px;
  min-width: 16.6666%;
}

.c-carousel__slide__inner--thumb {
  touch-action: manipulation;
  cursor: pointer;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  height: 134px;
  width: 100%;
  background-color: transparent;
  position: relative;
  display: block;
  overflow: hidden;
}

.c-carousel__slide__thumbnail {
  position: absolute;
  /* opacity: 0.2; */
  top: 0;
  bottom: 0;
  left: -10000%;
  right: -10000%;
  margin: auto;
  min-width: 1000%;
  min-height: 1000%;
  max-width: none;
  transform: scale(0.1);
  transition: opacity 0.2s;
}
