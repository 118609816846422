.c-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #e6e6e6;
  padding: 0 15px;
  font-size: $font-size-regular;
  color: $gray;

  &::placeholder {
    opacity: 0.5;
  }
}
