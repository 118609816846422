/* Only imported if the login page is rendered */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

@font-face {
  font-family: "Helvetica";
  src: url("../../assets/fonts/Helvetica-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
